<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-card-title class="pa-0">
       <v-row class="mb-1" no-gutters>
      <v-col cols="6" align-start class="px-5 py-2">
        <h4>Summary</h4>
      </v-col>
      <v-col cols="6" align="right" class="px-5 py-2">
        <v-autocomplete
        v-model="dateRange"
        dense
        style="max-width: 230px;"
        item-text="description"
        item-value="id"
        :items="filters"
        item-color="#38435a"
        height="40"
        hide-details
        solo-inverted
        flat
        :disabled="ac_disabled"
        color="#38435a"
        @change="selectDateRange()"
        @click="clickDateRange()"
        >
        <template slot="selection" slot-scope="{ item }">
            <span v-if="item.id == 7" class="text-no-wrap">{{displayCustomDateText(item.id)}}</span>
            <span v-else class="text-no-wrap">{{displayDateText(item.id)}}</span>
        </template>
        </v-autocomplete>
    <v-menu
      v-if="showDatePicker"
      ref="menu1"
      dense
      v-model="datePickerMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      class="mb-4"
      min-width="auto"
    >
      <v-date-picker
        v-model="selectedDate"
        range
        scrollable
        color="#38435a"
      >
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="datePickerMenu = false">
          close
        </v-btn>
        <v-btn
          color="#38435a"
          @click="saveDate(selectedDate)"
          dark
          class="elevation-0"
        >
          Apply
        </v-btn>
      </v-date-picker>
    </v-menu>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    </v-card-title>
    <v-row class="ma-2">
      <v-col cols="12">
        <v-row class="stats-style">
          <v-col cols="12" md="6" class="align-center">
            <!--START MEDIA-->
            <div class="my-3 text-center">
              <div>
                <img src="../../assets/img/oc-growing.svg" class="stats-img" alt="">
              </div>
              <h5>APPLICATIONS PROCESSED</h5>
              <h1>0</h1>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(0) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(0)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(0) + " " + "Change"}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(0) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(0,0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(0,0)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(0)+ " " + "yesterday" +  " " + "@" + "time" }}</span>
              </div>
            </div>
            <!--END MEDIA-->
          </v-col>
            <v-divider class="divider-style"
              vertical
            ></v-divider>
          <v-col cols="12" md="6" class="align-center">
             <!--START MEDIA-->
            <div class="my-3 text-center">
              <div>
                <img src="../../assets/img/oc-money-profits.svg" class="stats-img" alt="">
              </div>
              <h5>COMMISSIONS EARNED</h5>
              <h1>0</h1>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(0) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(0)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(0) + " " + "Change"}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(0) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(0,0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(0,0)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(0)+ " " + "yesterday" +  " " + "@" + "time" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
        mini: false,
        filters: [
          {id: 1, description: 'Today'},
          {id: 2, description: 'Yesterday'},
          {id: 3, description: 'Last 7 Days'},
          {id: 4, description: 'Last 30 Days'},
          {id: 5, description: 'This Month'},
          {id: 6, description: 'Last Month'},
          {id: 7, description: 'Custom Range'},
        ],
        startDate: null,
        endDate: null,
        dateRange: 1,
        dateSelectedText: null,
        search: '',
        data: {},
        loading: false,
        month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        showDatePicker: false,
        datePickerMenu: false,
        selectedDate: [],
        range_date_text: '',
        selectedDate2: [],
        showDatePicker2: false,
        ac_disabled: false,
    }),
    watch: {
    selectedDate2: {
      handler(data){
        if(data.length == 2){
          var s_date = new Date(data[0]);
          var e_date = new Date(data[1])
          var s_dd = s_date.getDate();
          var s_mm = String(s_date.getMonth() + 1).padStart(2, '0');
          var s_yyyy = s_date.getFullYear();
          var e_dd = String(e_date.getDate()).padStart(2, '0');
          var e_mm = String(e_date.getMonth() + 1).padStart(2, '0');
          var e_yyyy = e_date.getFullYear();
          this.startDate = parseInt(s_dd) + '-' + parseInt(s_mm) + '-' + s_yyyy;
          this.endDate = parseInt(e_dd) + '-' + parseInt(e_mm) + '-' + e_yyyy;
          this.initialize()
          if(s_yyyy == e_yyyy){
            this.range_date_text = this.month[parseInt(s_mm - 1)] + ' ' + s_dd + ' - ' + this.month[parseInt(e_mm - 1)] + ' ' + e_dd + ', ' + e_yyyy;
          }
          else{
            this.range_date_text = this.month[parseInt(s_mm - 1)] + ' ' + s_dd + ', ' + s_yyyy + ' - ' + this.month[parseInt(e_mm - 1)] + ' ' + e_dd + ', ' + e_yyyy;
          }
          }
          console.log(data)
          this.showDatePicker2 = false;
          this.ac_disabled = false;
      }
    }
    },
    methods: {
        initialize(){
          // if(this.startDate && this.endDate){
          //   this.loading = true
          // this.axiosCall('/dashboard/getStatistics?start_date=' + this.startDate + '&end_date=' + this.endDate, 'GET').then((res) => {
          //     if(res.data){
          //       this.loading = false
          //       this.data = res.data
          //       console.log(this.data)
          //       this.data.stripe_stats.forEach(item => {
          //         if(item.settings !== null && item.revenue_limit > 0){
          //           this.active_stripe_accounts_count += 1;
          //         }
          //       });
          //     }
          //     else{
          //       this.showToast2("Something went wrong!", 'error')
          //     }
          // })
          // }
        },
        displayDateText(id){
          var s_date = new Date();
          var e_date = new Date();
          this.showDatePicker = false;
          if(id == 2){
            let s_yesterday = new Date(s_date);
            let e_yesterday = new Date(e_date);
            s_yesterday.setDate(s_date.getDate() - 1);
            e_yesterday.setDate(e_date.getDate() - 1);
            s_date = s_yesterday;
            e_date = e_yesterday;
          }
          else if(id == 3){
            let last_7_days = new Date(s_date);
            last_7_days.setDate(s_date.getDate() - 6);
            s_date = last_7_days;
          }

          else if(id == 4){
            let last_30_days = new Date(s_date);
            last_30_days.setDate(s_date.getDate() - 29);
            s_date = last_30_days;
          }

          else if(id == 5){
            let first_day_of_month = new Date(s_date.getFullYear(), s_date.getMonth(), 1);
            let last_day_of_month = new Date(e_date.getFullYear(), e_date.getMonth() + 1, 0);
            s_date = first_day_of_month;
            e_date = last_day_of_month;
          }

          else if(id == 6){
            let first_day_of_month = new Date(s_date.getFullYear(), s_date.getMonth()-1, 1);
            let last_day_of_month = new Date(e_date.getFullYear(), e_date.getMonth(), 0);
            s_date = first_day_of_month;
            e_date = last_day_of_month;
          }
          
          var s_dd = s_date.getDate();
          var s_mm = String(s_date.getMonth() + 1).padStart(2, '0');
          var s_yyyy = s_date.getFullYear();
          var e_dd = String(e_date.getDate()).padStart(2, '0');
          var e_mm = String(e_date.getMonth() + 1).padStart(2, '0');
          var e_yyyy = e_date.getFullYear();
          this.startDate = parseInt(s_dd) + '-' + parseInt(s_mm) + '-' + s_yyyy;
          this.endDate = parseInt(e_dd) + '-' + parseInt(e_mm) + '-' + e_yyyy;
          if(s_yyyy == e_yyyy){
            return this.month[parseInt(s_mm - 1)] + ' ' + s_dd + ' - ' + this.month[parseInt(e_mm - 1)] + ' ' + e_dd + ', ' + e_yyyy;
          }
          else{
            return this.month[parseInt(s_mm - 1)] + ' ' + s_dd + ', ' + s_yyyy + ' - ' + this.month[parseInt(e_mm - 1)] + ' ' + e_dd + ', ' + e_yyyy;
          }
          
        },
        displayCustomDateText(id){
          console.log("hahah")
          if(id != 7){
            this.range_date_text = ""
          }
          return this.range_date_text;
        },
        saveDate(date) {
        if(date.length == 2){
            var s_date = new Date(date[0]);
            var e_date = new Date(date[1]);
            if(s_date > e_date){
              this.selectedDate = [date[1]];
            }
            else{
            var s_dd = s_date.getDate();
            var s_mm = String(s_date.getMonth() + 1).padStart(2, '0');
            var s_yyyy = s_date.getFullYear();
            var e_dd = String(e_date.getDate()).padStart(2, '0');
            var e_mm = String(e_date.getMonth() + 1).padStart(2, '0');
            var e_yyyy = e_date.getFullYear();
            this.startDate = parseInt(s_dd) + '-' + parseInt(s_mm) + '-' + s_yyyy;
            this.endDate = parseInt(e_dd) + '-' + parseInt(e_mm) + '-' + e_yyyy;
            this.initialize()
            if(s_yyyy == e_yyyy){
              this.range_date_text = this.month[parseInt(s_mm - 1)] + ' ' + s_dd + ' - ' + this.month[parseInt(e_mm - 1)] + ' ' + e_dd + ', ' + e_yyyy;
            }
            else{
              this.range_date_text = this.month[parseInt(s_mm - 1)] + ' ' + s_dd + ', ' + s_yyyy + ' - ' + this.month[parseInt(e_mm - 1)] + ' ' + e_dd + ', ' + e_yyyy;
            }
            this.$refs.menu1.save(this.selectedDate)
            this.showDatePicker = false;
            }
          }
          console.log(date)
      },
      selectDateRange(){
        if(this.dateRange >= 1 && this.dateRange <= 6){
          this.initialize();
        }
        else if(this.dateRange == 7){
          this.showDatePicker2 = true;
        }
      },
      clickDateRange(){
        if(this.dateRange == 7){
          this.ac_disabled = true;
          this.showDatePicker2 = true;
        }
      }
    },
    mounted(){
        var date = new Date();
        var dd = date.getDate();
        var mm = String(date.getMonth() + 1).padStart(2, '0');
        var yyyy = date.getFullYear();
        this.startDate = parseInt(dd) + '-' + parseInt(mm) + '-' + yyyy;
        this.endDate = parseInt(dd) + '-' + parseInt(mm) + '-' + yyyy;
        this.initialize()
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #e5f9f6 !important;
  color: #01c9a8 !important;
}
.chip-status-2{
  background-color: #fdf3f3 !important;
  color: #e12d2f !important;
}
.chip-status-3{
  background-color: #fbe996 !important;
  color: #413a29 !important;
}
.chip-status-4{
  background-color: #fefaf5 !important;
  color: #60b9cd !important;
}
.chip-status-5{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.stats-style h5{
  color: #8c98a4;
}
.stats-style .chip-text{
  color: #677788;
  font-weight: 600 ;
  font-size: .8125rem !important;
}
.divider-style{
  border-color: #eef0f7 !important;
}
.stats-img {
  width: 6.5625rem;
  height: auto;
  margin-bottom: 10px;
}
</style>